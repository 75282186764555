













































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
//@ts-ignore
import { VueGoodTable } from "vue-good-table";
import {
  UserRole,
  CommonData,
  UploadFolder,
  UploadFile,
  FileFilter,
  FileFolder
} from "@/models";
import DatePickerLang from "@/helpers/DatePickerLang";
//@ts-ignore
import VueTagsInput from "@johmun/vue-tags-input";
import UploadFileType from "@/helpers/UploadFileType";
import fileService from "@/services/FileService";
import FileService from "@/services/FileService";
import ModalDocument from "@/components/Modal/ModalDocument.vue";

@Component({
  components: { VueGoodTable, VueTagsInput, ModalDocument }
})
export default class Dashboard extends Vue {
  private columns: any[] = [];
  private rows: UploadFolder[] = [];
  private searchOptions: any = {
    enabled: true,
    skipDiacritics: true,
    placeholder: "nhập gì đó..."
  };
  private paginationOptions: any = {
    enabled: true,

    perPage: 10,
    perPageDropdown: [5, 10, 20, 50, 100],
    position: "bottom",
    jumpFirstOrLast: true,
    nextLabel: this.$t("common.table.next"),
    prevLabel: this.$t("common.table.prev"),
    firstLabel: "Đầu",
    lastLabel: "Cuối",
    rowsPerPageLabel: "Dòng/trang",
    ofLabel: "trên",
    pageLabel: "trang",
    allLabel: "Tất cả"
  };
  private selectedRow: UploadFolder = new UploadFolder();

  private eActionType: any = ActionType;
  private eFileFolder: any = FileFolder;
  private eCombobox: any = ECombobox;
  private datePickProp: DatePickerLang = new DatePickerLang();

  //
  private contractId: number = -1;
  private billId: number = -1;

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  //advance search
  private filterOption: FileFilter = new FileFilter();
  private isETA: boolean = true;
  private blockAdd: boolean = false;

  created() {
    this.getListFiles();
    this.setTableColumn();
  }

  getFileIcon(file: any) {
    return UploadFileType.getIconClassName(file.type);
  }

  getFileName(file: any) {
    return file.displayName;
  }

  uploadFile(formData?: FormData) {
    if (!formData) return;
    this.blockAdd = true;
    this.$blockui.show();
    FileService.uploadFile(formData)
      .then(res => {
        this.getListFiles();
        this.$swal.fire({
          icon: "success",
          title: "Tải lên thành công!",
          position: "bottom-end",
          showConfirmButton: false,
          toast: true,
          timer: 2000
        });
      })
      .catch(() => {
        this.$swal.fire({
          icon: "error",
          title: "Tải lên thất bại!",
          position: "bottom-end",
          showConfirmButton: false,
          toast: true,
          timer: 2000
        });
      })
      .finally(() => {
        this.blockAdd = false;
        this.$blockui.hide();
      });
  }

  uploadFileByInput(row: UploadFolder, folder: FileFolder) {
    let button = document.createElement("input");
    button.type = "file";
    button.multiple = true;
    button.onchange = (event: any) =>
      this.uploadFile(this.createFormData(row, folder, event));
    button.click();
  }

  createFormData(row: UploadFolder, folder: FileFolder, event: any) {
    if (
      this.blockAdd ||
      this.userRole === UserRole.GUEST ||
      this.userRole === UserRole.ACCOUNTANT
    )
      return;
    const allFile: File[] = event.target.files || event.dataTransfer.files;
    if (!allFile.length) return;
    const _50MB = 50 * 1048576;
    const files = [...allFile];
    if (files.some(file => file.size > _50MB)) {
      this.$swal.fire({
        icon: "warning",
        title:
          files.length === 1
            ? "File không thể lớn quá 50MB"
            : "Mỗi file không thể lớn quá 50MB",
        timer: 3000
      });
      return undefined;
    }

    const formData = new FormData();
    formData.set("contractId", String(row.contractId));
    if (folder === FileFolder.COMMON)
      row.billId ? formData.set("billId", String(row.billId)) : "";
    formData.set("folder", String(folder));
    files.forEach(file => {
      formData.append("files", file);
    });
    return formData;
  }

  /**
   * API get list bill
   */
  getListFiles() {
    const filterForAPI = new FileFilter(this.filterOption);

    filterForAPI.formatDate();

    fileService.getListFiles(filterForAPI).then(res => {
      this.rows = res.data.map((i: any) => new UploadFolder(i));
    });
  }

  downloadFile(file: UploadFile | any) {
    const fileName = file.fileName;
    FileService.dowloadFile(fileName).then(res => {
      if (res.status === 200) {
        const link = res.data;
        let a = document.createElement("a");
        a.href = link;
        if (file.type === UploadFileType.PDF) {
          a.setAttribute("target", "_blank");
        } else {
          a.setAttribute("download", fileName);
        }
        a.click();
      }
    });
  }

  openDocument(row: UploadFolder) {
    this.contractId = row.contractId;
    this.billId = row.billId;
    setTimeout(() => {
      this.$bvModal.show("modal-document-upload-folder");
    }, 10);
  }

  onSortDate(x: any, y: any, col: any, rowX: any, rowY: any) {
    // x - row1 value for column
    // y - row2 value for column
    // col - column being sorted
    // rowX - row object for row1
    // rowY - row object for row2

    let xVal = this.$moment(x, '"DD MMM YY"');
    let yVal = this.$moment(y, '"DD MMM YY"');
    if (!xVal.isValid() && !yVal.isValid()) return 0;
    if (!xVal.isValid()) return -1;
    if (!yVal.isValid()) return 1;
    return xVal.isBefore(yVal) ? -1 : !xVal.isBefore(yVal) ? 1 : 0;
  }

  toggleDropdown(id: number) {
    document.querySelectorAll("td.sticky-column").forEach(node => {
      node.setAttribute("style", `z-index: 1 !important;`);
    });

    setTimeout(() => {
      document
        .getElementById(`bill-id-${id}`)
        ?.parentElement?.setAttribute("style", `z-index: 2 !important;`);
    }, 1);
  }

  setTableColumn() {
    this.columns = [
      {
        label: "Mã HĐ/bill/invoice",
        tooltip: "Mã hợp đồng / bill / invoice",
        width: "145px",
        field: "code",
        tdClass: "h---1"
      },
      {
        label: "ETA",
        field: "eta",
        width: "98px",
        sortFn: this.onSortDate
      },
      {
        label: "INV+PL",
        field: "invPl",
        sortable: false,
        tdClass: "h---1 mw--40"
      },
      {
        label: "HC/CO/BL/INS",
        field: "hcCoBlIns",
        sortable: false,
        tdClass: "h---1 mw--40"
      },
      {
        label: "Tờ khai",
        field: "declare",
        sortable: false,
        tdClass: "h---1 mw--40"
      },
      {
        label: "15B",
        field: "b15",
        sortable: false,
        tdClass: "h---1 mw--40"
      },
      {
        label: "Ngân hàng",
        field: "bank",
        sortable: false,
        tdClass: "h---1 mw--40"
      },
      {
        label: "Khách",
        field: "guest",
        sortable: false,
        tdClass: "h---1 mw--40"
      },
      {
        label: "Tài liệu chung",
        field: "common",
        sortable: false,
        tdClass: "h---1 mw--40"
      }
    ];
  }
}

enum ActionType {
  DETAIL,
  UPDATE,
  STATUS
}

export enum ECombobox {
  ORIGIN,
  COMPANY
}
